* {
    padding: 0;
    margin: 0;
}

.w3l-headers-9 ul.menu a.link-nav {
    color: black !important;
}

.blog-page {
    background: linear-gradient(to right, #F5FFF5, white);
}

.bannerSlides {
    background-repeat: no-repeat !important;
    background-size: cover !important;
}

.content img {
    width: 100%;
}